import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Glide, { Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

import './carousel.scss';

const Carousel = ({ children, className, uniqueCarouselName, options }) => {
    const defaultOptions = {
        autoplay: 5000,
        perView: 1,
    };

    useEffect(() => {
        new Glide(`.${uniqueCarouselName}`, {
            ...defaultOptions,
            ...options,
        }).mount({
            Autoplay,
        });
    }, []);

    return (
        <div className={`glide ${uniqueCarouselName} ${className}`}>
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                    {children.map((child, ix) => {
                        const key = `slide-${ix}`;
                        return (
                            <li key={key} className="glide__slide">
                                {child}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
Carousel.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    options: PropTypes.shape({
        autoplay: PropTypes.number,
        perView: PropTypes.number,
    }),
    uniqueCarouselName: PropTypes.string.isRequired,
};

Carousel.defaultProps = {
    className: '',
    options: {},
};

export default Carousel;
